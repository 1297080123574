<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div class="wrap">
        <router-link class="goBack" to="/">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z" fill="#0033A0"/>
          </svg>
          назад
        </router-link>

        <h1>
          <span>Архив выданных заключений</span>
        </h1>


        <table class="table">
          <thead>
            <tr>
              <th>Номер</th>
              <th>Номер заявления
                <v-icon @click="sortAssessments('applicationId')">
                  mdi-swap-vertical
                </v-icon>
              </th>
              <th>Дата выдачи заключения
                <v-icon @click="sortAssessments('localDateTime')">
                  mdi-swap-vertical
                </v-icon>
              </th>
              <th>ФИО специалиста
                <v-icon @click="sortAssessments('userFullName')">
                  mdi-swap-vertical
                </v-icon>
              </th>
              <th>ФИО гражданина
                <v-icon @click="sortAssessments('applicantFullName')">
                  mdi-swap-vertical
                </v-icon>
              </th>
            </tr>
          </thead>
          <tbody v-if="apiLoaded">
            <tr
                v-for="(assessment, assessmentIndex) in assessments"
                :key="'assessmentRowI'+assessmentIndex"
                @click="$router.push('/assessment/'+assessment.id)"
                class="clickable-tr"
            >
              <td>{{assessmentIndex+1}}</td>
              <td>{{assessment.applicationId}}</td>
              <td>{{assessment.localDateTime | dateFormat}}</td>
              <td>{{assessment.userFullName}}</td>
              <td>{{assessment.applicantFullName}}</td>
            </tr>
          </tbody>
        </table>
        <v-btn rounded
               v-if="this.page+1 < this.totalPages"
               @click="loadMore"
        >
          Загрузить ещё
        </v-btn>
      </div>
    </transition>
  </div>
</template>

<script>


import api from "@/modules/api";

export default {
  name: 'Archive',
  data() {
    return {
      assessments: [],
      apiLoaded: false,
      apiError: false,
      sort: 'localDateTime',
      initialSort: 'localDateTime',
      direction: 'asc',
      initialDirection: 'asc',
      page: 0,
      initialPage: 0,
      amount: 50,
      totalPages: 0,
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      const mdate = new Date(date)
      const options = {
        //timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    async loadAssessments(reset = false) {
      let req = await api.get('/assessments?page=' + this.page + '&size=' + this.amount + '&sort=' + this.sort + ',' + this.direction)
      if (req.ok) {
          const pages = req?.payload?.page?.totalPages
          if (pages) {
              this.totalPages = pages
          }
          const assessments = req.payload._embedded.assessments
          if(reset) {
              this.assessments = assessments
              return
          }
          this.assessments = [...this.assessments, ...assessments]
      } else alert('Ошибка при обращении к серверу!')
    },
      async loadMore() {
          this.page++
          await this.loadAssessments()
      },
      async sortAssessments(sort) {
          this.page = this.initialPage
          this.sort = sort
          this.direction = this.direction === 'asc' ? 'desc' : 'asc'
          await this.loadAssessments(true)
      }
  },
  async beforeMount() {
    await this.loadAssessments()
    this.apiLoaded = true
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>


<style scoped lang="scss">
.clickable-tr:hover td{
  cursor: pointer;
  background: #DFE9FF;
}
</style>
